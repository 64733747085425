import service, { serviceTypes, fetcher } from "../../services";
import AuthState from "../../redux/states/auth";
import cartCommands from "./index";

export default async function verifySignature({
  razorpay_payment_id,
  razorpay_order_id,
  razorpay_signature,
}) {
  try {
    const authState = AuthState.get();
    const user = authState.user;
    var serviceType = undefined;

    //auth setter part with optional test data for guest
    if (typeof user === "object") {
      serviceType = serviceTypes.verifySignatureService();
    } else {
      throw new Error("Login data error, try logout and login again.");
    }

    if (serviceType) {
      serviceType.params.razorpay_order_id = razorpay_order_id;
      serviceType.params.razorpay_payment_id = razorpay_payment_id;
      serviceType.params.razorpay_signature = razorpay_signature;
    }

    const { response, error } = await service(serviceType);
		
		if (error) {
      window.notify([
        {
          message: error.message || "Error occured in confirming order",
          type: "danger",
        },
      ]);
    } else if (response) {
      if (response.data?.success === true) {
        window.notify([
          {
            message: response.data?.message || "Order Confirmed successfully",
            type: "success",
          },
        ]);
        return response;
      } else {
        throw new Error(
          response.data?.message || "Error occured in confirming order"
        );
      }
    }
  } catch (error) {
    window.notify([
      {
        message: error.message || "Error occured in confirming order",
        type: "danger",
      },
    ]);
  }
}
