import React, { Component } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import { useHistory } from "react-router-dom";
import "./css/index.scoped.css";

function ItemWithRouter(props) {
  const history = useHistory();
  return (
    <ListGroupItem
      {...props}
      onClick={() => {
        switch (props.item.class_name) {
          case "Category":
            history.push(
              "/shop?page=1&per_page=12&category_id[]=" + props.item.class_id
            );
            break;
          case "SubCategory":
            history.push(
              `/shop?page=1&per_page=12&category_id[]=${
                props.item.category_id
              }&sub_category_id[]=${props.item.class_id}`
            );
            break;
          case "Product":
            history.push("/shop/" + props.item.class_id);
            break;
        }
      }}
    />
  );
}

export class ExitSearch extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="yt-recent-search-wrap ">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <p className="recent-search-tag-name m-0">Quick Results</p>
          <img
            src={require("./images/close-icn.png")}
            onClick={() => this.props.hideSearch()}
          />
        </div>
        <ListGroup className="recent-search-list-wrap" flush>
          {this.props.results.map((item, idx) => {
            console.log(item, "MYITEM");
            return (
              <ItemWithRouter
                className="px-0 w3-hover-opacity"
                item={item}
                style={{ cursor: "default" }}
                key={idx}
              >
                {item.name}
              </ItemWithRouter>
            );
          })}
        </ListGroup>
      </div>
    );
  }
}

export default ExitSearch;
