import cartAction from "../types/cartActionTypes";

export const passCategoryData = (data) => {
  return {
    type: cartAction.GET_CATEGOTY,
    payload: data,
  };
};

export const clearCartOrders = () => {
  return {
    type: cartAction.CLEAR_CART_ORDERS
  }
}